$container: 1300px;
$layout-margin: 4.86vw;
$layout-margin-mob: 2vw;
$base-color: #333;
$accent-color: #bdbdbd;
$hover-color: #fff;
$active-color: rgb(0, 170, 252);
$disabled: rgba(51, 51, 51, 0.37);
$bg-color: #fff;
$contrast-color: #263238;
$border: 1px solid $disabled;
$brr: 16px;
//$brrmin: ;
//$brrmax: ;
$ind: 20px; // Indentation
$indmin: 10px;
//$indmax: ;
$gap: 20px;
$gapmin: 10px;
//$gapmax: ;
$transition: 0.3s ease-out;

// Colors

$white: #fff;
$aquamarine: #66ccc7;
$lightblue: #98bbdd;
$lightpurple: #e2a7c2;
$darkblue: #211f5c;
$green: #6c7e2a;
$lightgreen: #e6e4b3;
$black: #1f150a;
$orange: #ffb02e;
$lightorange: #ffb841;
$lightyellow: #f5ff66;
$lightbrown: #9f8170;
$darkgrey: #4c514a;
