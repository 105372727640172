@font-face {
	font-family: 'Open Sans';
	src: url('../fonts/OpenSans-ExtraBoldItalic.woff2') format('woff2'),
		url('../fonts/OpenSans-ExtraBoldItalic.woff') format('woff');
	font-weight: bold;
	font-style: italic;
	font-display: swap;
}

@font-face {
	font-family: 'Open Sans';
	src: url('../fonts/OpenSans-Bold.woff2') format('woff2'),
		url('../fonts/OpenSans-Bold.woff') format('woff');
	font-weight: bold;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Open Sans';
	src: url('../fonts/OpenSans-ExtraBold.woff2') format('woff2'),
		url('../fonts/OpenSans-ExtraBold.woff') format('woff');
	font-weight: bold;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Open Sans';
	src: url('../fonts/OpenSans-Medium.woff2') format('woff2'),
		url('../fonts/OpenSans-Medium.woff') format('woff');
	font-weight: 500;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Open Sans';
	src: url('../fonts/OpenSans-MediumItalic.woff2') format('woff2'),
		url('../fonts/OpenSans-MediumItalic.woff') format('woff');
	font-weight: 500;
	font-style: italic;
	font-display: swap;
}

@font-face {
	font-family: 'Open Sans';
	src: url('../fonts/OpenSans-BoldItalic.woff2') format('woff2'),
		url('../fonts/OpenSans-BoldItalic.woff') format('woff');
	font-weight: bold;
	font-style: italic;
	font-display: swap;
}

@font-face {
	font-family: 'Open Sans';
	src: url('../fonts/OpenSans-LightItalic.woff2') format('woff2'),
		url('../fonts/OpenSans-LightItalic.woff') format('woff');
	font-weight: 300;
	font-style: italic;
	font-display: swap;
}

@font-face {
	font-family: 'Open Sans';
	src: url('../fonts/OpenSans-Light.woff2') format('woff2'),
		url('../fonts/OpenSans-Light.woff') format('woff');
	font-weight: 300;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Open Sans';
	src: url('../fonts/OpenSans-Italic.woff2') format('woff2'),
		url('../fonts/OpenSans-Italic.woff') format('woff');
	font-weight: normal;
	font-style: italic;
	font-display: swap;
}

@font-face {
	font-family: 'Open Sans';
	src: url('../fonts/OpenSans-SemiBold.woff2') format('woff2'),
		url('../fonts/OpenSans-SemiBold.woff') format('woff');
	font-weight: 600;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Open Sans';
	src: url('../fonts/OpenSans-Regular.woff2') format('woff2'),
		url('../fonts/OpenSans-Regular.woff') format('woff');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Open Sans';
	src: url('../fonts/OpenSans-SemiBoldItalic.woff2') format('woff2'),
		url('../fonts/OpenSans-SemiBoldItalic.woff') format('woff');
	font-weight: 600;
	font-style: italic;
	font-display: swap;
}

@font-face {
	font-family: 'Open Sans SemiCondensed';
	src: url('../fonts/OpenSansSemiCondensed-Medium.woff2') format('woff2'),
		url('../fonts/OpenSansSemiCondensed-Medium.woff') format('woff');
	font-weight: 500;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Open Sans SemiCondensed';
	src: url('../fonts/OpenSansSemiCondensed-ExtraBoldItalic.woff2')
			format('woff2'),
		url('../fonts/OpenSansSemiCondensed-ExtraBoldItalic.woff') format('woff');
	font-weight: bold;
	font-style: italic;
	font-display: swap;
}

@font-face {
	font-family: 'Open Sans SemiCondensed';
	src: url('../fonts/OpenSansSemiCondensed-LightItalic.woff2') format('woff2'),
		url('../fonts/OpenSansSemiCondensed-LightItalic.woff') format('woff');
	font-weight: 300;
	font-style: italic;
	font-display: swap;
}

@font-face {
	font-family: 'Open Sans SemiCondensed';
	src: url('../fonts/OpenSansSemiCondensed-Regular.woff2') format('woff2'),
		url('../fonts/OpenSansSemiCondensed-Regular.woff') format('woff');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Open Sans SemiCondensed';
	src: url('../fonts/OpenSansSemiCondensed-Light.woff2') format('woff2'),
		url('../fonts/OpenSansSemiCondensed-Light.woff') format('woff');
	font-weight: 300;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Open Sans SemiCondensed';
	src: url('../fonts/OpenSansSemiCondensed-MediumItalic.woff2') format('woff2'),
		url('../fonts/OpenSansSemiCondensed-MediumItalic.woff') format('woff');
	font-weight: 500;
	font-style: italic;
	font-display: swap;
}

@font-face {
	font-family: 'Open Sans SemiCondensed';
	src: url('../fonts/OpenSansSemiCondensed-SemiBold.woff2') format('woff2'),
		url('../fonts/OpenSansSemiCondensed-SemiBold.woff') format('woff');
	font-weight: 600;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Open Sans SemiCondensed';
	src: url('../fonts/OpenSansSemiCondensed-Italic.woff2') format('woff2'),
		url('../fonts/OpenSansSemiCondensed-Italic.woff') format('woff');
	font-weight: normal;
	font-style: italic;
	font-display: swap;
}

@font-face {
	font-family: 'Open Sans SemiCondensed';
	src: url('../fonts/OpenSansSemiCondensed-BoldItalic.woff2') format('woff2'),
		url('../fonts/OpenSansSemiCondensed-BoldItalic.woff') format('woff');
	font-weight: bold;
	font-style: italic;
	font-display: swap;
}

@font-face {
	font-family: 'Open Sans SemiCondensed';
	src: url('../fonts/OpenSansSemiCondensed-ExtraBold.woff2') format('woff2'),
		url('../fonts/OpenSansSemiCondensed-ExtraBold.woff') format('woff');
	font-weight: bold;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Open Sans SemiCondensed';
	src: url('../fonts/OpenSansSemiCondensed-Bold.woff2') format('woff2'),
		url('../fonts/OpenSansSemiCondensed-Bold.woff') format('woff');
	font-weight: bold;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Open Sans SemiCondensed';
	src: url('../fonts/OpenSansSemiCondensed-SemiBoldItalic.woff2')
			format('woff2'),
		url('../fonts/OpenSansSemiCondensed-SemiBoldItalic.woff') format('woff');
	font-weight: 600;
	font-style: italic;
	font-display: swap;
}

@font-face {
	font-family: 'Open Sans Condensed';
	src: url('../fonts/OpenSansCondensed-Light.woff2') format('woff2'),
		url('../fonts/OpenSansCondensed-Light.woff') format('woff');
	font-weight: 300;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Open Sans Condensed';
	src: url('../fonts/OpenSansCondensed-LightItalic.woff2') format('woff2'),
		url('../fonts/OpenSansCondensed-LightItalic.woff') format('woff');
	font-weight: 300;
	font-style: italic;
	font-display: swap;
}

@font-face {
	font-family: 'Open Sans Condensed';
	src: url('../fonts/OpenSansCondensed-SemiBoldItalic.woff2') format('woff2'),
		url('../fonts/OpenSansCondensed-SemiBoldItalic.woff') format('woff');
	font-weight: 600;
	font-style: italic;
	font-display: swap;
}

@font-face {
	font-family: 'Open Sans Condensed';
	src: url('../fonts/OpenSansCondensed-BoldItalic.woff2') format('woff2'),
		url('../fonts/OpenSansCondensed-BoldItalic.woff') format('woff');
	font-weight: bold;
	font-style: italic;
	font-display: swap;
}

@font-face {
	font-family: 'Open Sans Condensed';
	src: url('../fonts/OpenSansCondensed-Medium.woff2') format('woff2'),
		url('../fonts/OpenSansCondensed-Medium.woff') format('woff');
	font-weight: 500;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Open Sans Condensed';
	src: url('../fonts/OpenSansCondensed-ExtraBoldItalic.woff2') format('woff2'),
		url('../fonts/OpenSansCondensed-ExtraBoldItalic.woff') format('woff');
	font-weight: bold;
	font-style: italic;
	font-display: swap;
}

@font-face {
	font-family: 'Open Sans Condensed';
	src: url('../fonts/OpenSansCondensed-Italic.woff2') format('woff2'),
		url('../fonts/OpenSansCondensed-Italic.woff') format('woff');
	font-weight: normal;
	font-style: italic;
	font-display: swap;
}

@font-face {
	font-family: 'Open Sans Condensed';
	src: url('../fonts/OpenSansCondensed-Bold.woff2') format('woff2'),
		url('../fonts/OpenSansCondensed-Bold.woff') format('woff');
	font-weight: bold;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Open Sans Condensed';
	src: url('../fonts/OpenSansCondensed-ExtraBold.woff2') format('woff2'),
		url('../fonts/OpenSansCondensed-ExtraBold.woff') format('woff');
	font-weight: bold;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Open Sans Condensed';
	src: url('../fonts/OpenSansCondensed-SemiBold.woff2') format('woff2'),
		url('../fonts/OpenSansCondensed-SemiBold.woff') format('woff');
	font-weight: 600;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Open Sans Condensed';
	src: url('../fonts/OpenSansCondensed-Regular.woff2') format('woff2'),
		url('../fonts/OpenSansCondensed-Regular.woff') format('woff');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Open Sans Condensed';
	src: url('../fonts/OpenSansCondensed-MediumItalic.woff2') format('woff2'),
		url('../fonts/OpenSansCondensed-MediumItalic.woff') format('woff');
	font-weight: 500;
	font-style: italic;
	font-display: swap;
}

@font-face {
	font-family: 'Athelas';
	src: url('../fonts/Athelas-Bold.woff2') format('woff2'),
		url('../fonts/Athelas-Bold.woff') format('woff');
	font-weight: bold;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Athelas';
	src: url('../fonts/Athelas-Italic.woff2') format('woff2'),
		url('../fonts/Athelas-Italic.woff') format('woff');
	font-weight: normal;
	font-style: italic;
	font-display: swap;
}

@font-face {
	font-family: 'Athelas';
	src: url('../fonts/Athelas-Regular.woff2') format('woff2'),
		url('../fonts/Athelas-Regular.woff') format('woff');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Athelas';
	src: url('../fonts/Athelas-BoldItalic.woff2') format('woff2'),
		url('../fonts/Athelas-BoldItalic.woff') format('woff');
	font-weight: bold;
	font-style: italic;
	font-display: swap;
}

@font-face {
	font-family: 'Antic Didone';
	src: url('../fonts/AnticDidone-Regular.woff2') format('woff2'),
		url('../fonts/AnticDidone-Regular.woff') format('woff');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}
