.video-presentation {
	position: relative;
	display: block;

	&__video {
		video {
			width: 100%;
			height: auto;
		}

		/* .play-icon {
			position: absolute;
			inset-block-start: 50%;
			inset-inline-start: 50%;
			transform: translate(-50%, -50%);
			height: 130px;
			width: 150px;
			padding: 10px 20px;
			stroke: var(--white);
			transition: all $transition;

			&:hover {
				border: 1px solid #fff;
				border-radius: 20%;
			}
		} */
	}

	.plyr {
		&__poster {
			background-size: cover;
		}
		&__control {
			min-width: revert;
		}
		&__controls {
			display: grid;
			grid-template-columns: auto 1fr auto auto auto auto auto;
		}
	}
}

@media screen and (max-width: 767px) {
	.video-presentation {
		display: flex;
		flex-direction: column-reverse;
	}
}
