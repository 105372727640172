.contacts-main {
	margin-block-start: 150px;
	margin-block-end: 100px;

	h1 {
		font-size: 42px;

		text-align: center;
	}

	&-content {
		display: flex;
		flex-direction: column;

		align-items: center;

		a {
			width: auto;
			height: 50px;
			border: 50px;
			svg {
				width: 50px;
				height: 50px;
			}
		}
	}
}
