.about {
	&-img {
		position: relative;

		h1 {
			position: absolute;
			inset-block-start: 50%;
			inset-inline-start: 50%;
			transform: translate(-50%, -50%);
			color: var(--white);
			font-family: 'Athelas', serif;
		}
	}
	img {
		margin: 0;
		min-height: 600px;
	}

	&-main-text {
		margin-block-start: 50px;
	}

	&-row {
		margin-block-start: 50px;
	}
}
