.burger {
	@include translateY;

	display: none;
	position: absolute;
	z-index: 100;
	width: 30px;
	height: 20px;
	inset-block-start: 50%;
	inset-inline-end: 0;
	cursor: pointer;

	&__inner {
		cursor: pointer;
		position: relative;
		z-index: 100;
		inset-block-start: 0;
		inset-inline-end: 0;
		height: 100%;

		&:focus {
			border: 1px solid $base-color;
		}

		div {
			display: block;
			position: absolute;
			width: 100%;
			height: 2px;
			background-color: $accent-color;

			&:nth-child(1) {
				inset-block-start: 0;
				transition: transform $transition;
			}

			&:nth-child(2) {
				@include translateAll;

				inset-block-start: 50%;
				inset-inline-start: 50%;
				transition: transform $transition;
			}

			&:nth-child(3) {
				inset-block-end: 0;
				transition: transform $transition;
			}
		}

		&--active {
			div {
				&:nth-child(1) {
					inset-block-start: 9px;
					transform: rotate(45deg);
				}

				&:nth-child(2) {
					transform: scale(0);
					opacity: 0;
				}

				&:nth-child(3) {
					inset-block-end: 9px;
					transform: rotate(-45deg);
				}
			}
		}
	}
}

@media screen and (max-width: 920px) {
	.burger {
		display: block;
	}

	.layout-none {
		.burger {
			inset-inline-end: $layout-margin-mob;
		}
	}
}
