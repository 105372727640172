.main {
	position: relative;
	background: var(--gray);
	padding-bottom: 80px;

	&-bg {
		&-1 {
			width: 721px;

			img {
				height: 942px;
			}
		}

		&-2 {
			justify-self: end;
			padding-right: 50px;
			width: 548px;

			img {
				height: 786px;
			}
		}
	}

	&-meaning {
		position: absolute;
		inset-block-start: 20%;
		inset-inline-start: 50%;
		transform: translateX(-50%);
		display: flex;
		flex-direction: column;
		align-items: center;
		flex-wrap: wrap;
		color: var(--white);

		&__descr {
			font-size: clamp(1rem, 11.3vw - 4rem, 3.125rem);
		}

		&__btn {
			display: block;
			min-width: 200px;

			padding: 7px 60px;
			margin: 150px auto 0;

			border: 1px solid #333;
			border-radius: $brr;

			cursor: pointer;
			color: $base-color;
			font-size: clamp(1rem, 5.3vw - 1.3rem, 2rem);
			font-weight: inherit;

			color: var(--white);
			background: var(--ruby);
			appearance: none;
			transition: all 0.3s ease-out;

			&:hover {
				color: $base-color;
				background-color: $hover-color;
				border-color: $accent-color;
			}

			&:active {
				background-color: $active-color;
				border-color: transparent;
			}

			&:focus {
				box-shadow: 0 0 0 3px rgb(0 170 252 / 52%);
				outline: none;

				&:hover {
					background-color: $active-color;
					color: #fff;
					border-color: transparent;
				}
			}

			&.active {
				border-color: transparent;
				background-color: $active-color;
				color: #fff;

				&:hover {
					background-color: #0c9ade;
				}
			}
		}
	}
}

@media screen and (max-width: 920px) {
	.main {
		&-bg {
			&-1 {
				width: 100%;

				img {
					height: 600px;
				}
			}

			&-2 {
				display: none;
			}
		}
	}
}

@media screen and (max-width: 425px) {
	.main {
		&-meaning {
			&__btn {
				min-width: 100%;
			}
		}
	}
}
