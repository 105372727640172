.header {
	display: grid;
	grid-template-columns: auto 1fr;
	align-items: center;
	position: relative;
	background: var(--gray);
	color: var(--white);
	font-size: 1.5rem;

	&-menu {
		justify-self: end;
		width: 100%;
		padding: 0 50px;

		&__list {
			@include gap;

			display: flex;
			justify-content: space-between;
			padding-inline-start: 0;
			margin-block: 0;
		}

		&__item {
			display: flex;
			align-items: center;

			&::before {
				display: none;
			}

			a {
				display: block;
				padding: 10px;
				border-color: transparent;
				transition: all $transition;

				&:hover {
					border-color: $accent-color;
				}
			}
		}
	}
}

.logo {
	border-bottom: 0;
	width: 170px;

	img {
		height: 80px;
	}
}

@media screen and (max-width: 920px) {
	.header {
		&-menu {
			position: fixed;
			z-index: 100;
			width: 100%;
			height: 100%;
			inset-inline-end: 0;
			inset-block-end: 0;
			transform: translateY(-100%);
			background-color: transparent;
			opacity: 0;
			transition: all $transition;

			&__list {
				@include translateAll;

				flex-direction: column;
				position: absolute;
				inset-block-start: 50%;
				inset-inline-start: 50%;
			}

			&__item {
				display: block;
				text-align: center;
				color: #fff;
				opacity: 0;

				a {
					&:hover {
						border-color: transparent;
					}
				}
			}

			&--active {
				opacity: 1;
				background-color: $contrast-color;
				transform: translateY(0);

				.header-menu__item {
					opacity: 1;
				}
			}
		}
	}
}
