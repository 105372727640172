.grid {
	margin-block-start: 50px;

	&__row {
		grid-template-columns: 1fr 1fr;
		gap: 0;

		&:nth-of-type(1) {
			.grid-img {
				background: url('../img/material.jpg') center center/cover no-repeat;
			}
		}
		&:nth-of-type(2) {
			.grid-img {
				background: url('../img/stamina.jpg') center center/cover no-repeat;
			}
		}
		&:nth-of-type(3) {
			.grid-img {
				background: url('../img/thermal-insulation.jpeg') center center/cover
					no-repeat;
			}
		}
		&:nth-of-type(4) {
			.grid-img {
				background: url('../img/hypoallergenic.jpg') center center/cover
					no-repeat;
			}
		}
		&:nth-of-type(5) {
			.grid-img {
				background: url('../img/legacy.jpeg') center center/cover no-repeat;
			}
		}
		&:nth-of-type(6) {
			.grid-img {
				background: url('../img/repair.jpeg') center center/cover no-repeat;
			}
		}
	}

	&-text {
		min-height: 400px;
		background: var(--gray);
		color: var(--white);
		padding: 50px;

		h4 {
			font-size: 1.5rem;
		}

		/* p {
			margin: 0;
		} */
	}

	&-img {
		min-height: 400px;
	}
}

@media screen and (max-width: 1024px) {
	.grid {
		&__row {
			display: flex;
			flex-direction: column;
			&:nth-child(2) {
				flex-direction: column-reverse;
			}
		}
	}
}
