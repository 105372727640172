button {
	display: block;
	min-width: 200px;
	border: 1px solid #333;
	border-radius: $brr;
	padding: 7px 60px;
	margin: 0 auto;
	transition: all 0.3s ease-out;
	appearance: none;
	cursor: pointer;
	background-color: var(--white);
	color: $base-color;
	font-size: clamp(1rem, 5.3vw - 1.3rem, 2rem);
	font-weight: inherit;

	&:hover {
		color: $base-color;
		background-color: $hover-color;
		border-color: $accent-color;
	}

	&:active {
		background-color: $active-color;
		border-color: transparent;
	}

	&:focus {
		box-shadow: 0 0 0 3px rgb(0 170 252 / 52%);
		outline: none;

		&:hover {
			background-color: $active-color;
			color: #fff;
			border-color: transparent;
		}
	}

	&.active {
		border-color: transparent;
		background-color: $active-color;
		color: #fff;

		&:hover {
			background-color: #0c9ade;
		}
	}
}

form {
	button {
		margin-block: 0;
	}
}

@media screen and (max-width: 425px) {
	button {
		min-width: 100%;
	}
}
