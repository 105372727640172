.video-descr {
	position: absolute;
	inset-block-start: -100px;
	inset-inline-end: 0;
	z-index: 1;
	padding: 40px;
	width: 500px;
	background: var(--white);

	p {
		margin: 0;

		&:nth-child(2) {
			padding-top: 20px;
		}
	}
}

@media screen and (max-width: 767px) {
	.video-descr {
		position: relative;
		width: 100%;
	}
}
