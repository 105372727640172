.table {
  width: 100%;
  border-spacing: 0;
  border-collapse: collapse;

  &__wrapper {
    max-width: 1300px;
    margin: 0 auto;
  }

  &__caption {
    text-align: start;
    padding: 10px;
  }

  &__header, &__data {
    padding: 10px;
    border: $border;
    text-align: start;
  }
}

@media screen and (max-width: 768px) {
  .table {
    &__wrapper {
      overflow-x: scroll;
      white-space: nowrap;
    }
  }
}
