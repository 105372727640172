.product {
	margin-block: 50px;
	display: block;
	&-row {
		&-img {
			grid-template-columns: 400px auto;
			align-items: center;
		}
	}
	&__img {
		text-align: center;
		img {
			height: 600px;
		}
	}
}
