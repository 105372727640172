img {
  display: block;
  margin-block-start: $ind;
  margin-block-end: $ind;
  width: 100%;
  height: 300px;
  object-fit: cover;
}

.img {
  max-width: 600px;

  &__float {
    &--left {
      float: left;
    }

    &--right {
      float: right;
    }
  }

  &__empty {
    margin-block: 0;
    img {
      margin-block: 0;
    }
  }
}
